import React, { useEffect, useState } from 'react';
import './App.css';

import 'firebaseui/dist/firebaseui.css';
import { getAuth, User } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { getDatabase, onValue, ref } from 'firebase/database';

// import through index.html
import Paddle from './paddle';

import { AuthWall } from './AuthWall';

import { Spinner } from '@blueprintjs/core';

import '@blueprintjs/core/lib/css/blueprint.css';

Paddle.Environment.set('sandbox');

const SANDBOX_VENDOR_ID = 6393;
Paddle.Setup({ vendor: SANDBOX_VENDOR_ID });

const firebaseConfig = {
  apiKey: 'AIzaSyCgMSjD3ClQWOu_cUTqoY03980H2M7bnLg',
  authDomain: 'captain-rec.firebaseapp.com',
  databaseURL: 'https://captain-rec-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'captain-rec',
  storageBucket: 'captain-rec.appspot.com',
  messagingSenderId: '583881313271',
  appId: '1:583881313271:web:7332a71356f6f1b47dfcf2',
};

const THE_BLUE = 'rgb(85, 120, 184)';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const realtimeDatabase = getDatabase(app);

// const ui = new firebaseui.auth.AuthUI(firebase.auth());

const checkout = (product: number, userId: string, email: string, loadCallback: () => void) => {
  Paddle.Checkout.open({
    method: 'inline',
    product,
    email,
    loadCallback,
    allowQuantity: false,
    disableLogout: true,
    passthrough: JSON.stringify({ userId }),
    frameTarget: 'checkout-container', // The className of your checkout <div>
    frameInitialHeight: 416,
    frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none; height: 100%; outline: none', // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
  });
};

const plans = [
  {
    title: 'Starter',
    price: '3£/month',
    description: '5 transcription hours\n500 MB storage\nCross platform app\nSync transcriptions to Notion',
    planId: 29633,
  },
  {
    title: 'Pro',
    price: '8£/month',
    description: '20 transcription hours\n5GB storage\nCross platform app\nSync transcriptions to Notion',
    planId: 29630,
  },
  {
    title: 'Custom',
    price: undefined,
    description: 'Need more or cover your whole team? Reach out to discuss a custom option',
    planId: -1,
  },
];

function App() {
  const [planChosen, setPlanChosen] = useState<number | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionPlan, setSubscriptionPlan] = useState<
    undefined | { planId: string; status: string; cancelUrl: string | undefined; expiresOn: string | undefined }
  >(undefined);
  const [hasLoadedSubscription, setHasLoadedSubscription] = useState(false);
  const [paddleIsLoading, setPaddleIsLoading] = useState(false);

  useEffect(() => {
    if (user !== undefined) {
      const realtimeRef = ref(realtimeDatabase, `/user/${user?.uid}/subscription`);

      onValue(realtimeRef, (snapshot) => {
        const value = snapshot.val();
        if (!hasLoadedSubscription) {
          setHasLoadedSubscription(true);
        }
        setSubscriptionPlan(value ?? undefined);
      });
    }
  }, [hasLoadedSubscription, user]);

  useEffect(() => {
    auth.onAuthStateChanged((u) => {
      setIsLoading(false);
      if (u) {
        setUser(u);
      }
    });
  });

  if (isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (user === undefined) {
    return <AuthWall />;
  }

  const planElements = plans.map((plan) => {
    return (
      <div
        key={plan.planId}
        className="w-100-mobile"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <div style={{ fontSize: '32px' }}>{plan.title}</div>
        <div
          className="w-100-mobile w-200"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: plan.planId?.toString() === subscriptionPlan?.planId ? 'rgb(108, 143, 249)' : THE_BLUE,
            padding: 32,
            borderRadius: 16,
            color: 'white',
            margin: '16px',
            position: 'relative',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <div style={{ fontSize: '32px', paddingBottom: '8px' }}>{plan.price}</div>
            <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
              {plan.description.split('\n').map((el) => (
                <div key={el} style={{ display: 'flex', fontSize: '12px', textAlign: 'left' }}>
                  <span style={{ width: '10px', flexShrink: 0 }}>&#8226; </span>
                  {el}
                </div>
              ))}
            </div>
          </div>

          {plan.planId !== -1 ? (
            <div>
              <button
                style={{
                  padding: '12px',
                  background: 'white',
                  borderRadius: '8px',
                  color: 'black',
                  marginTop: '20px',
                  cursor: 'pointer',
                  borderWidth: '0px',
                  width: '100%',
                }}
                onClick={async () => {
                  if (plan.planId.toString() === subscriptionPlan?.planId && subscriptionPlan.status !== 'cancelled') {
                    window.open(subscriptionPlan.cancelUrl);
                  } else if (plan.planId.toString() !== subscriptionPlan?.planId && subscriptionPlan !== undefined) {
                    // change plan
                    await fetch(
                      window.origin === 'http://localhost:8080'
                        ? 'http://localhost:3000/changeSubscription'
                        : 'https://api.captainrec.app/changeSubscription',
                      {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${await user.getIdToken()}`,
                        },
                        body: JSON.stringify({ planId: plan.planId }),
                      }
                    );
                  } else {
                    setPlanChosen(plan.planId);
                    setPaddleIsLoading(true);
                    checkout(plan.planId, user.uid, user.email ?? '', () => {
                      setPaddleIsLoading(false);
                    });
                  }
                }}
              >
                {plan.planId.toString() === subscriptionPlan?.planId && subscriptionPlan.status !== 'cancelled'
                  ? 'Cancel'
                  : plan.planId.toString() === subscriptionPlan?.planId
                  ? 'Renew'
                  : subscriptionPlan !== undefined
                  ? 'Use this Plan'
                  : 'Subscribe'}
              </button>
            </div>
          ) : (
            <button
              style={{
                padding: '12px',
                borderRadius: '8px',
                borderStyle: 'solid',
                color: 'white',
                cursor: 'pointer',
                backgroundColor: THE_BLUE,
                borderWidth: '1px',
                borderColor: 'white',
              }}
              onClick={() => {
                window.location.href = 'mailto:jonas@limevillagelabs.cloud?subject=Pricing';
              }}
            >
              Contact us
            </button>
          )}
          {planChosen !== undefined && plan.planId !== planChosen ? (
            <div
              onClick={() => {
                if (plan.planId !== null) {
                  setPlanChosen(plan.planId);
                  setPaddleIsLoading(true);
                  checkout(plan.planId, user.uid, user.email ?? '', () => {
                    setPaddleIsLoading(false);
                  });
                } else {
                  window.location.href = 'mailto:jonas@limevillagelabs.cloud?subject=Pricing';
                }
              }}
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
                backgroundColor: 'grey',
                opacity: 0.5,
                borderRadius: 16,
              }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  });

  const currentPlan = plans.find((el) => el.planId?.toString() === subscriptionPlan?.planId);

  if (!hasLoadedSubscription) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="App w-100-mobile"
      style={{
        display: 'flex',
        padding: '4px',
        flexDirection: 'row',
        height: '100vh',
        justifyContent: 'center',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="./captn.png" className="logo-width" alt="CaptainRec logo" />
          <div
            style={{ display: 'flex', fontSize: '20px', paddingLeft: '20px', flexDirection: 'column', height: '100%' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>
              <div className="mobile-none" style={{ paddingTop: '8px' }}>
                Manage your subscription
              </div>
              <div className="mobile-only" style={{ height: '8px' }}></div>
              <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{ backgroundColor: 'rgba(232, 230, 230, 0.4)', borderRadius: '8px', marginBottom: '8px' }}>
                  <div
                    style={{
                      fontSize: '14px',
                      paddingLeft: '16px',
                      paddingTop: '16px',
                      paddingBottom: '8px',
                      paddingRight: '16px',
                    }}
                  >
                    Logged in as {user.email}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                      style={{
                        paddingTop: '8px',
                        padding: '16px',
                        fontSize: '14px',
                        borderRadius: 4,
                        border: 'none',
                        background: 'none',
                        fontWeight: 500,
                      }}
                    >
                      {subscriptionPlan === undefined ? '' : `${currentPlan?.title} plan`}
                    </div>
                    <button
                      style={{
                        padding: '8px',
                        paddingRight: '16px',
                        fontSize: '14px',
                        color: 'rgb(29, 44, 243)',
                        borderRadius: 4,
                        border: 'none',

                        cursor: 'pointer',
                        background: 'none',
                      }}
                      onClick={() => {
                        auth.signOut();
                        window.location.reload();
                      }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-100-mobile"
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'rgba(232, 230, 230, 0.4)',
            height: '100%',
          }}
        >
          <div
            className="w-100-mobile"
            style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '728px' }}
          >
            <div style={{ height: '16px', flexShrink: 0 }}></div>
            <div
              className="w-100-mobile"
              style={{
                fontSize: '18px',
                padding: '16px',
                backgroundColor: 'white',
                borderRadius: '15px',
                boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);',
              }}
            >
              {currentPlan === undefined ? (
                'You are currently not subscribed'
              ) : (
                <>
                  {subscriptionPlan?.status === 'cancelled' ? (
                    <>
                      Your <span style={{ color: THE_BLUE, fontWeight: 400 }}>{currentPlan.title}</span> plan will
                      expire on
                      {` ${new Date(subscriptionPlan.expiresOn ?? '').toLocaleDateString('en-gb', {
                        dateStyle: 'long',
                      })}`}
                    </>
                  ) : (
                    <>
                      You are on the <span style={{ color: THE_BLUE, fontWeight: 400 }}>{currentPlan.title}</span> plan
                      ({currentPlan.price})
                    </>
                  )}
                </>
              )}
            </div>
            <div style={{ height: '16px', flexShrink: 0 }}></div>
            <div
              style={{
                backgroundColor: planChosen ? '#eeecec' : undefined,
                borderTopRightRadius: '15px',
                borderTopLeftRadius: '15px',
                height: '100%',
              }}
            >
              <div className="mobile-only" style={{ display: planChosen ? 'flex' : 'none' }}>
                <div
                  style={{
                    padding: '8px',
                    marginBottom: '8px',
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '24px',
                    flexGrow: 1,
                    backgroundColor: THE_BLUE,
                  }}
                >
                  <div style={{ color: 'white', flexGrow: 1 }}>
                    {plans.find((el) => el.planId === planChosen)?.title}
                  </div>
                  <div style={{ color: 'white', marginRight: '8px', fontSize: '14px' }}>
                    {plans.find((el) => el.planId === planChosen)?.price}
                  </div>
                  <button
                    style={{
                      padding: '12px',
                      background: 'white',
                      borderRadius: '8px',
                      color: 'black',
                      cursor: 'pointer',
                      borderWidth: '0px',
                    }}
                    onClick={() => {
                      setPlanChosen(undefined);
                    }}
                  >
                    Change Plan
                  </button>
                </div>
              </div>
              <div>
                {planChosen ? (
                  <></>
                ) : (
                  <div id="plans" style={{ paddingTop: '16px', padding: '16px', maxWidth: 800 }}>
                    {planElements}
                  </div>
                )}
              </div>
              <div
                style={{ display: planChosen ? 'flex' : 'none', height: '100%', flexDirection: 'column' }}
                className="checkout-container"
              >
                {paddleIsLoading ? <Spinner /> : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
