import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, EmailAuthProvider, signInWithCustomToken } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import React, { useCallback } from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyCgMSjD3ClQWOu_cUTqoY03980H2M7bnLg',
  authDomain: 'captain-rec.firebaseapp.com',
  databaseURL: 'https://captain-rec-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'captain-rec',
  storageBucket: 'captain-rec.appspot.com',
  messagingSenderId: '583881313271',
  appId: '1:583881313271:web:7332a71356f6f1b47dfcf2',
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const ui = new firebaseui.auth.AuthUI(auth);

// eslint-disable-next-line @typescript-eslint/ban-types
export const AuthWall: React.FC<{}> = (props) => {
  const ref = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      signInWithCustomToken(auth, token);
    } else {
      ui.start('#login', {
        signInSuccessUrl: window.origin,
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          GoogleAuthProvider.PROVIDER_ID,
          EmailAuthProvider.PROVIDER_ID,
        ],
        tosUrl: 'https://captainrec.app/terms-of-service',
        privacyPolicyUrl: 'https://captainrec.app/privacy-policy',
      });
    }
  }, []);

  return <div ref={ref} id="login"></div>;
};
